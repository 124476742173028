

.columns {
    width: 75%;
    max-width: $small-max-columns-width;
    min-width: $small-min-columns-width;
    margin: 0 auto; }

.container {
    width: 100%;
    h1 {
        margin: 0; } }

[class^="checkpoint"] {
    position: relative;
    z-index: 1;
    display: block;
    width: $checkpoint-width;
    margin: 5em 0;

    h1 {
        span {
            font-size: .6em;
            letter-spacing: 2px; } } }

.checkpoint-right {
    margin-left: $small-displacement; }

.page-lines-container {
    position: absolute;
    z-index: 0;
    left: 10px;
    right: 0;
    top: 0;
    bottom: 0;
    width: calc(100% - 20px);
    height: 100%;
    margin: 0 auto;

    path {
        fill: none;
        position: relative;
        stroke-linecap: round; }
    .line-bg {
        stroke: $line-color;
        stroke-width: 1px; }
    .line-fill {
        transition: stroke-dashoffset 0.25s ease-out;
        stroke: $text-color;
        stroke-width: 3px; }
    .line-dashed {
        z-index: 99;
        top: 20px;
        stroke: $main-bg-color;
        stroke-width: 4px;
        stroke-dasharray: 20 10;
        stroke-dashoffset: 15; }
    circle {
        fill: $line-color;
        transition: fill 0.3s ease-out;
        &.active {
            fill: $text-color; }
        &.hollow {
            fill: $main-bg-color;
            stroke: $line-color;
            stroke-width: 3px;
            transition: stroke 0.3s ease-out;
            &.active {
                stroke: $text-color; } } }
    #line-mask-container {
        path {
            z-index: 100;
            stroke: $main-bg-color;
            stroke-width: 4px; } } }


@media #{$large-up} {
    .projects-container {
        position: relative;
        display: flex;
        flex-direction: column;
        .project-container {
            display: block;
            margin-top: -1em;
            margin-bottom: -1em;
            margin: 0; }
        >div, .project-container {
            position: relative;
            width: 47%;
            &:nth-child(2n + 1) {
                top: 0;
                left: 53%; }

            &:nth-child(2n) {} } } }



