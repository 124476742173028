

footer {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 4em 0;
    background-color: $secondary-bg-color;

    .container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;

        .upper, .mid, .bottom {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between; }

        .upper {
            h1 {
                margin-top: 0;
                margin-bottom: 0.25em; }
            .footer-logo {
                margin-top: 0.25em;
                width: $small-displacement;
                height: auto;
                img {
                    width: 100%;
                    height: auto; } } }
        .mid {
            margin-top: 3em;
            p {
                font-weight: 400;
                font-size: 1em; }
            .links {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    margin-top: 0.75em;
                    a {
                        text-decoration: underline;
                        font-weight: 200; } } } }
        .bottom {
            margin-top: 3em;
            margin-bottom: 2.75em;
            .copyright {
                font-size: 10px; }
            .slogan {
                font-size: 12px;
                font-family: $utopia-font;
                text-transform: uppercase;
                letter-spacing: 1px;
                span {
                    font-family: $roboto-font;
                    font-weight: 900;
                    margin-right: 0.33em; } } } } }
