

.button {
    position: relative;
    display: inline-block;
    background-color: #eee;
    border: 0;
    color: #111;
    cursor: pointer;
    transition: 0.3s all ease-out;
    &::after {
        position: absolute;
        content: "";
        top: 5px;
        left: 0;
        right: 0;
        z-index: -1;
        height: 100%;
        width: 100%;
        transform: scale(0.9) translateZ(0);
        filter: blur(15px);
        background: linear-gradient(to left,#ff5770,#e4428d,#c42da8,#9e16c3,#6501de,#9e16c3,#c42da8,#e4428d,#ff5770);
        background-size: 200% 200%;
        animation: animateGlow 1.25s linear infinite; }

    @media #{$large-up} {
        font-size: 18px; }
    &.white {
        background: #eee;
        color: $main-bg-color; }
    &.round {
        padding: .6em 1.5em;
        border-radius: 2em;
        margin-left: 0.3em; }
    &:active {
        background-color: #fff; } }



.cta-button {
    position: relative;
    display: inline-block;
    background-color: $main-bg-color;
    color: $tertiary-text-color;
    z-index: 5;
    border: 0;
    margin-top: 1rem;
    padding: .6em 1.5em;
    border-radius: 2em;
    margin-left: 0.3em;
    cursor: pointer;
    transition: 0.3s all ease-out;
    &::before, &::after {
        content: '';
        position: absolute;
        border-radius: 2em; }
    &::after {
        z-index: -2;
        top: -18px;
        left: -18px;
        right: -18px;
        bottom: -18px;
        opacity: 0.75;
        background-size: 200% 200% !important;
        box-shadow: inset 0 0 12px 12px $main-bg-color, inset 0 0 3px 2px $main-bg-color;
        background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
        animation: moving-gradient 5s ease infinite; }
    &:before {
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $main-bg-color; } }


@keyframes moving-gradient {
    0% {
        background-position: 0% 50%; }
    50% {
        background-position: 100% 50%; }
    100% {
        background-position: 0% 50%; } }
