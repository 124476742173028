

.hidden {
    display: none !important; }
.hidden-large-up {
    @media #{$large-up} {
        display: none !important; } }
.hidden-large-only {
    @media #{$large-only} {
        display: none !important; } }
.hidden-large-down {
    @media #{$large-down} {
        display: none !important; } }
.hidden-medium-up {
    @media #{$medium-up} {
        display: none !important; } }
.hidden-medium-down {
    @media #{$medium-down} {
        display: none !important; } }
.hidden-small-only {
    @media #{$small-only} {
        display: none !important; } }

@media #{$medium-down} {
    #main {
        padding-top: 10em; } }



@media #{$large-up} {
    h1 {
        font-size: 48px;
        line-height: 52px; }

    p {
        font-size: 24px; }

    .columns {
        width: 100%;
        max-width: $large-max-columns-width;
        min-width: $large-min-columns-width; }

    .container {
        position: relative;
        width: 50%;
        margin: 0 auto; }

    main.container {
        padding-top: 15em; }
    .page-lines-container {
        left: -40px;
        height: calc(100% + 15em);
        width: calc(100% + 80px); }

    [class^="checkpoint"] {
        width: $large-checkpoint-width;
        margin: 15em 0; }

    .checkpoint-right {
        margin-left: $large-displacement; }

    nav {
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        width: 100%;
        .container {
            width: 50% + 2 * $column-width + 2 * $gutter-width; }
        .nav-logo {
            width: $large-logo;
            max-width: 70px; } }

    .reviews-section {
        .review {
            .review-text, .reviewee, .reviewee-title {
                font-size: 20px;
                line-height: 25px; } } }

    footer {
        .footer-logo {
            max-width: 70px; } }
    .team {
        margin-top: -10em;
        margin-bottom: 6em;
        .member {
            .member-photo {
                img {
                    width: 150px;
                    height: 150px;
                    border-radius: 100px; }
                .network {
                    width: 175px;
                    height: 175px;
                    border-radius: 100px;
                    #network {
                        width: 225px;
                        height: 225px;
                        left: -15%;
                        top: -20%; } } }
            .member-info {
                width: 70%;
                h2 {
                    font-size: 42px;
                    line-height: 46px; }
                p {
                    font-size: 20px; } } } } }
