@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,600,700");
@import url("https://use.typekit.net/srd8ksr.css");
$utopia-font: utopia-std, serif;
$roboto-font: "Roboto", sans-serif;

// $dark-blue-bg-color: #040415
$main-bg-color: #1A1A1A;
$secondary-bg-color: #111111;
$text-color: #fff;
$secondary-text-color: #eee;
$tertiary-text-color: #ddd;
$inverse-text-color: #1a1a1a;
$line-color: #707070;


// AUTH COLOURS
$discord-color: #7289DA;
$twitch-color: #6441A4;
$twitter-color: #1DA1F2;
$instagram1-color: #5851db;
$instagram2-color: #fcaf45;

// SHADOW
$shadow-2: -4px -4px 10px #3A3A3A,  4px 4px 10px #000000,  4px 4px 10px #000000, -4px -4px 10px #3A3A3A;
$shadow:  7px 7px 14px #646464, -7px -7px 14px #999999;
$text-shadow: 20px 20px 60px #171717, -20px -20px 60px #1d1d1d;


// GRID
$columns: 12;
$gutters: $columns - 1;
$column-width: 7.3%;
$gutter-width: 1.13%;
$columns-width: $gutters * $gutter-width + $columns * $column-width;
$checkpoint-width: 8 * $gutter-width + 9 * $column-width;

// small vars
$small-min-columns-width: 310px;
$small-max-columns-width: 400px;
$small-displacement: 3 * $column-width + 2 * $gutter-width;
$small-logo: 2 * $column-width + 1 * $gutter-width;

// large vars
$large-min-columns-width: 900px;
$large-max-columns-width: 1640px;
$large-logo: 1.25 * $column-width;
$large-checkpoint-width: 83%;
$large-displacement: 2 * $column-width + 1 * $gutter-width;



$desktop-navbar-container-width: 1020px;


// Media and responsive vars
$small-range: 48em;
$medium-range1: 48.063em;
$medium-range2: 62em;
$large-range1: 62.063em;
$large-range2: 90em;
$xlarge-range1: 90.063em;
$xlarge-range2: 120em;
$xxlarge-range1: 120.063em;
$xxlarge-range2: 99999999em;

$screen: "only screen";

// $landscape: "#{$screen} and (orientation: landscape)"
// $portrait: "#{$screen} and (orientation: portrait)"

$small-up: $screen;
$small-only: "#{$screen} and (max-width: #{$small-range})";
$small-med-only: "#{$screen} and (max-width: 50em)";

$medium-down: "#{$screen} and (max-width:#{$medium-range2})";
$medium-up: "#{$screen} and (min-width:#{$medium-range1})";
$medium-only: "#{$screen} and (min-width:#{$medium-range1}) and (max-width:#{$medium-range2})";

$large-up: "#{$screen} and (min-width:#{$large-range1})";
$large-down: "#{$screen} and (max-width:#{$large-range1})";
$large-only: "#{$screen} and (min-width:#{$large-range1}) and (max-width:#{$large-range2})";

$xlarge-up: "#{$screen} and (min-width:#{$xlarge-range1})";
$xlarge-only: "#{$screen} and (min-width:#{$xlarge-range1}) and (max-width:#{$xlarge-range2})";

$xxlarge-up: "#{$screen} and (min-width:#{$xxlarge-range1})";
$xxlarge-only: "#{$screen} and (min-width:#{$xxlarge-range1}) and (max-width:#{$xxlarge-range2})";
