

.scroll-transition {
    transition: 1s opacity, 1s transform ease-out; }

[class*="fade"] {
    opacity: 0 !important; }

.fade-up {
    transform: translateY(100px); }

.fade-right {
    transform: translateX(-20px); }

.fade-left {
    transform: translateX(20px); }

.slide-down {
    transform: translateY(-100px); }
