
nav {
    position: relative;
    z-index: 1;
    padding: 1em 0;
    .nav-logo {
        display: block;
        width: $small-logo;
        img {
            width: 100%;
            height: auto; } } }

.menu-icon {
    width: 2.3em;
    height: 1.75em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    span {
        width: 100%;
        height: 2px;
        background-color: $main-bg-color;
        transition: 0.3s all ease-out; } }


#hamburger-menu {
    position: fixed;
    z-index: 999999;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2em;
    background-color: #fff;
    color: $inverse-text-color;
    clip-path: circle(100px at 100% 100%);
    -moz-clip-path: circle(100px at 100% 100%);
    -webkit-clip-path: circle(100px at 100% 100%);
    transition: 0.3s all ease-out;
    cursor: pointer;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            margin: 2em 0;
            a {
                text-decoration: none;
                .text {
                    color: $inverse-text-color;
                    font-size: 1.5em;
                    font-weight: 400;
                    letter-spacing: 3px;
                    position: relative;
                    font-size: 24px;
                    text-transform: uppercase;
                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 2px;
                        width: 0;
                        background-color: $main-bg-color;
                        transition: 0.2s width ease-out; }
                    &:hover {
                        &::before {
                            width: 50%; } } } }
            &.active {
                a .text {
                    font-weight: 900;
                    &::before {
                        width: 50%; } } } } }
    .mail-link {
        position: absolute;
        bottom: 0;
        left: 2em;
        margin: 0;
        margin-bottom: 1em;
        text-decoration: none;

        p {
            font-size: 14px;
            font-weight: bold;
            color: $main-bg-color;
            text-decoration: none; }
        .text {
            text-transform: none; } }

    .menu-icon {
        position: absolute;
        right: 1.25em;
        bottom: 1.25em; }

    &.menu-open {
        z-index: 9999999999;
        border-top-right-radius: 100vw;
        clip-path: circle(141.2% at 0 100%);
        -webkit-clip-path: circle(141.2% at 0 100%);
        -moz-clip-path: circle(141.2% at 0 100%); } }

.menu-open {
    .menu-icon {
        .second {
            width: 0; }
        .first {
            transform-origin: top right;
            transform: rotate(-45deg); }
        .third {
            transform-origin: bottom right;
            transform: rotate(45deg); } } }

#desktop-nav {
    position: absolute;
    padding: 2em 0;
    padding-top: 1em;
    right: 0;
    top: 0;
    width: 275px;
    background-color: transparent;
    color: $main-bg-color;
    clip-path: circle(30px at calc(100% - 50px) 50px);
    box-shadow: 0px 0px 25px rgba(255, 255, 255, .15);
    transition: 0.3s all ease-out;

    .menu-icon {
        position: absolute;
        right: 30px;
        top: 30px;
        span {
            background: $text-color; } }

    ul {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        list-style: none;
        padding: 1em 3em;
        margin: 0;
        li {
            margin-top: 2em;
            >a {
                display: flex;
                color: $main-bg-color;
                .icon {
                    img {
                        width: 35px;
                        height: auto; } }
                .text {
                    position: relative;
                    font-size: 24px;
                    text-transform: uppercase;
                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 2px;
                        width: 0;
                        background-color: $main-bg-color;
                        transition: 0.2s width ease-out; }
                    &:hover {
                        &::before {
                            width: 50%; } } } }
            &.active {
                .text {
                    letter-spacing: 3px;
                    font-weight: 900;
                    &::before {
                        width: 50%; } } }
            &.mail-link {
                text-decoration: none;
                p {
                    font-size: 14px;
                    font-weight: bold;
                    color: $main-bg-color;
                    text-decoration: none; }
                .text {
                    text-transform: none; } }
            &.menu-icon {
                margin-left: 0; } } }
    &.menu-open {
        clip-path: circle(450px at calc(100% - 40px) 30px);
        background-color: $text-color;

        .menu-icon {
            span {
                background: $main-bg-color; } } } }
