.team {
    position: relative;
    margin-top: 5em;

    #diagonal {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        line {
            stroke: $text-color; } }

    .member {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-bottom: 10em;

        .member-info {
            h2 {
                font-size: 30px;
                line-height: 32px; }
            p {
                font-weight: 300;
                font-size: 16px;
                margin-top: 0.2em;
                margin-bottom: .5em; } }

        .member-photo {
            img {
                width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 50px;
                border: 2px solid $text-color; }
            .network {
                display: block;
                position: relative;
                border-radius: 100px;
                background-color: $text-color;
                border: 2px solid $text-color;
                width: 125px;
                height: 125px;
                overflow: hidden;
                #network {
                    width: 175px;
                    height: 175px;
                    position: absolute;
                    left: -20%;
                    top: -25%; } } }

        &.member-left {
            .member-info {
                margin-right: 1em; }
            img {
                box-shadow: 0px 3px 6px #4326FF63; } }
        &.member-right {
            .member-info {
                margin-left: 1em; }
            img {
                box-shadow: 0px 3px 6px #FFF12629; } } } }

