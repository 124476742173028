body {
    margin: 0;
    background-color: #1a1a1a; }

.container-404 {
    color: #fff;
    background-color: #0c0c26;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Fira Sans", Avenir, "Helvetica Neue", "Lucida Grande", sans-serif;
    height:  80vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        display: inline-block;
        border-right: 1px solid rgba(255, 255, 255,.5);
        margin: 0;
        margin-right: 20px;
        padding: 10px 23px 10px 0;
        font-size: 24px;
        font-weight: 500;
        vertical-align: top; }

    .text-container {
        display: inline-block;
        text-align: left;
        line-height: 49px;
        height: 49px;
        vertical-align: middle;

        h2 {
            font-size: 14px;
            font-weight: normal;
            line-height: inherit;
            margin: 0;
            padding: 0; } } }

.MuiButton-root {
    color: rgb(1, 191, 255) !important; }
