$internal-container-width: 850px;

#main {
    padding-top: 20em; }

#opener {
    margin: 0; }

#wave-animation {
    position: fixed;
    z-index: -2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.blur-anim {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    animation: rotating-glow 15s linear infinite;
    &.reverse {
        animation-direction: reverse; }
    .blur {
        position: absolute;
        width: 350px;
        height: 350px;
        margin: -175px -175px;
        border-radius: 100px;
        animation: moving-glow 15s ease infinite;
        @media #{$small-only} {
            width: 150px;
            height: 150px;
            border-radius: 50px;
            margin: -75px -75px; }
        &.blur1 {
            left: 40%;
            top: 60%;
            background: radial-gradient(circle at center,  #f68e95, transparent 50%); }
        &.blur2 {
            left: 40%;
            top: 60%;
            background: radial-gradient(circle at center,  #d297ef, transparent 50%);
            animation-delay: 3s; }
        &.blur3 {
            background: radial-gradient(circle at center,  #8eeaff, transparent 50%);
            left: 60%;
            top: 40%;
            animation-delay: 8s;
            animation-direction: reverse; } } }

@keyframes rotating-glow {
    0% {
        transform: rotate(0deg); }
    100% {
        transform: rotate(360deg); } }

@keyframes moving-glow {
    0% {
        transform: translate(0, 0); }
    33% {
        transform: translate(-20px, 20px); }
    66% {
        transform: translate(20px, -20px); }
    100% {
        transform: translate(0, 0); } }

@media #{$small-only} {
    @keyframes moving-glow {
        0% {
            transform: translate(0, 0); }
        33% {
            transform: translate(-10px, 10px); }
        66% {
            transform: translate(10px, -10px); }
        100% {
            transform: translate(0, 0); } } }


#van-anim {
    position: relative;
    top: 0;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 56.25%;
    @media #{$small-only} {
        width: 100%;
        margin: 0; }

    #single-image {
        img {
            opacity: 1; } }

    img {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        opacity: 0;
        &.actived-van-image {
            // position: relative
            opacity: 1; } } }

.projects-container {
    margin-top: 5em; }
