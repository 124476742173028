

#clipboard-input {
    position: absolute;
    left: -1000px;
    opacity: 0; }
.email-container {
    position: relative;
    button {
        display: inherit;
        color: inherit;
        background-color: transparent;
        box-shadow: none;
        border: 0;
        margin: 0;
        padding: 0;
        cursor: pointer; }

    .email-status {
        position: absolute;
        z-index: -1;
        opacity: 0;
        bottom: 50%;
        left: 0;
        padding: 5px 15px;
        width: max-content;
        display: flex;
        font-size: 12px;
        letter-spacing: 1px;
        color: #fff;
        background-color: #21a1e1;
        border-radius: 7px;
        transition: all .3s ease-out;
        &::before {
            content: "";
            position: absolute;
            top: 99%;
            left: 33%;
            width: 1px;
            height: 1px;
            border: 7px solid transparent;
            border-top-color: #21a1e1; }
        &.active {
            z-index: 9999;
            bottom: 120%;
            opacity: 1; } } }
