.diagonal-svg-page {
    [class^="checkpoint"] {
        margin: 15em 0;
        @media #{$large-up} {
            margin: 20em 0; } }
    .checkpoint.opener {
        margin-top: 5em; }

    .closer {
        margin-bottom: 7.5em; }

    .checkpoint-right {
        margin-left: $small-displacement; }

    #last-checkpoint {
        margin-bottom: 5em;
        @media #{$large-up} {
            margin-bottom: 15em; } } }
