

.project-container {
    display: block;
    width: 100%;
    margin-bottom: 5em;

    &:hover {
        color: $text-color;
        text-decoration: none; }

    .image-container {
        position: relative;
        width: 100%;
        padding-top: 100%;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.4);
            transition: 0.3s transform ease-out; } }

    .data-container {
        margin-top: 2em;
        transition: 0.3s transform ease-out;
        h2 {
            margin: 0;
            font-size: 2em;
            font-family: $utopia-font; }
        p {
            font-size: 1em; } }

    &:hover {
        .image-container {
            img {
                transform: scale(1.1); } }
        .data-container {
            transform: translateY(-1em); } } }
