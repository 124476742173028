

.reviews-section {
    margin-top: 3em;
    .review {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: 0.3s all ease-out;
        .review-text, .reviewee, .reviewee-title {
            font-size: 14px;
            line-height: 20px;
            margin: 0; }
        .review-text {
            position: relative;
            z-index: 2;
            font-family: 'Times New Roman', Times, serif;
            letter-spacing: .5px;
            font-style: italic;
            font-weight: 100;
            &::before {
                content: "";
                display: block;
                position: absolute;
                z-index: -1;
                left: -1em;
                top: -0.5em;
                width: 40px;
                height: 30px;
                background-image: url("/assets/icons/quote.svg"); } }
        .reviewee {
            font-weight: 900;
            margin-top: 0.75em; }
        &.active {
            position: relative;
            opacity: 1;
            display: block; } }

    .reviews-controller {
        display: flex;
        margin-top: 2em;
        padding-left: .75em;
        button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 40px;
            border-radius: 20px;
            span {
                display: block;
                width: 16px;
                height: 16px;
                border: 1px solid transparent;
                border-left-color: #333;
                border-top-color: #333;
                transform-origin: center;
                transform: rotate(-45deg); }
            &.left {
                span {
                    margin-left: 0.5em; } }
            &.right {
                margin-left: 2em;
                span {
                    transform: rotate(-225deg);
                    margin-right: 0.5em; } } } } }
